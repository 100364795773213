import React from "react";
import MainLayout from "../../layouts/MainLayout";
import LandingSection from "../../section/LandingSection";
const LandingPage = () => {
  return (
    <MainLayout>
      <LandingSection />
    </MainLayout>
  );
};

export default LandingPage;
