export const AuthenticationField = ({autoComplete="off", label,errors,register, name, type = "text", placeholder, icon }) => {
  return (
    <div>
      <div className="relative">
      <input
        autoComplete={"new-password"}
        {...register(name)}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        className="w-full h-[50px] rounded-[7px] border-[1px] border-[solid] border-clientMidGrey bg-clientMidWhiteSmoke pl-[54px] mt-[25px]"
      ></input>
      <img
        src={icon}
        alt="UserIcon"
        className="w-[24px] h-[24px] m-[0] rounded-none absolute bottom-[13px] left-[15px]"
      ></img>
    </div>
    {errors[name] && <p className="mt-1 text-sm text-red-600">{errors[name].message}</p>}
    </div>
  );
};
