import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function ServiceCard({ ServiceCardData, expandedCard, toggleReadMore }) {

  const CustomPrevArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="absolute left-0 lg:top-[160px] sm:top-[150px] top-[100px] z-10 transform -translate-y-1/2 bg-gray-700 text-white rounded-full p-2 hover:bg-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
    );
  };
  
  const CustomNextArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="absolute right-0 lg:top-[160px] sm:top-[150px] top-[100px] transform -translate-y-1/2 bg-gray-700 text-white rounded-full p-2 hover:bg-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    );
  };
  
  var settings = {
    // dots: true,
    arrows: true, // Disable default arrows
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 654,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  };

  return (
    <>
      <div className="relative w-full">
        <Slider {...settings}>
          {ServiceCardData.map((CardData, index) => {
            return (<div

              className={`max-w-[321px]   h-auto mb-[20px] w-full`}
              key={index}
            >
             
              <div className="mx-[5px]">
                <img
                  src={CardData.image}
                  alt={CardData.image}
                  className="w-full h-auto m-[0]"
                ></img>

                <div className="mt-[20px] w-full h-auto md:h-[calc(3*1.5rem)] tablet:h-[calc(4*1.5rem)] mobile:h-[calc(3*1.5rem)]">
                  <p className="font-lejour text-[clamp(16px,_3vw,_19px)] font-normal leading-[34.2px] uppercase m-0 text-clientBlack ">
                    {CardData.headingStart}{" "}
                    <span className="font-glacial text-[clamp(16px,_3vw,_19px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_1)]">
                      {CardData.headingMiddle}
                    </span>
                    <span className="font-bold">/</span>
                    {CardData.headingEnd}
                  </p>
                </div>
                <div className="mt-[35px] lg:mt-[35px] md:mt-[35px] sm:mt-[40px] mobile:mt-[25px]  h-auto w-full">
                  {expandedCard === CardData.id
                    ? CardData.listingPint.map((listItem, index) => {
                      return (
                        <li
                          className="font-glacial text-[16px] font-normal leading-[23.84px] m-0 text-clientDarkCharcoal "
                          key={index}
                        >
                          {listItem}
                        </li>
                      );
                    })
                    : ""}

                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="w-[101px] h-[36px] rounded-[4px] border-[1.5px] border-[solid] border-primary font-glacial text-[15px] font-normal leading-[15px] text-clientBlack"
                  onClick={() => toggleReadMore(CardData.id)}>
                  {expandedCard === CardData.id ? 'Read Less' : 'Read More'}
                </button>
              </div>
            
            </div>)
          })}
        </Slider>
      </div>
    </>
  );
}
// ${!expandedCard?"flex flex-col justify-between":""}
export default ServiceCard;
