import React from "react";
import MainLayout from "../../layouts/MainLayout";
import BlogDetailSection from "../../section/BlogDetailSection";
const BlogDetailPage = () => {
  return (
    <MainLayout>
      <BlogDetailSection />
    </MainLayout>
  );
};

export default BlogDetailPage;
