import BlogDetailBgImg from "../../assets/marketing/blog_detail_bg.webp";
import BlogDetail1 from "../../assets/marketing/blog_detail1.webp";
import BlogDetail2 from "../../assets/marketing/blog_detail2.webp";
import { blogDetailList } from "../pages/blog/BlogCardData";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { BlogCardData } from "../pages/blog/BlogCardData";
import { Helmet } from 'react-helmet';

const BlogDetailSection = () => {
  const { goBack } = useHistory();
  const search = useParams();

  const blogDetail = BlogCardData.filter((item)=>{
    return (item.id==search.id)
  })

 if(blogDetail.length==0){
  goBack();
 }
  
  return (
    <>
    <Helmet>
        <title>{blogDetail[0]?.seo?.title}</title>
        <meta
          name="description"
          content={blogDetail[0]?.seo?.metaDesc}
        />
        <meta
          name="keywords"
          content={`${blogDetail[0]?.seo?.primaryKeyword} ${blogDetail[0]?.seo?.primaryKeyword}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": blogDetail[0]?.seo?.title,
            "description": blogDetail[0]?.seo?.description,
            "keywords": `${blogDetail[0]?.seo?.primaryKeyword} ${blogDetail[0]?.seo?.primaryKeyword}`,
          })}
        </script>
      </Helmet>
      <div className="max-w-[1280px] mx-auto px-4 sm:px-6 md:px-8 lg:px-8 xl:px-0 pt-[80px]">

        <div className="mx-auto h-full relative">
          <div className="w-full bg-white max-w-[1280px] h-auto" >
            <img alt={blogDetail[0]?.seo?.imgAlt} className="w-full h-auto m-0 rounded-none" src={BlogDetailBgImg}></img>
          </div>
          <button onClick={goBack} className="absolute left-[10%] top-[30px] bg-clientWhite rounded-[6px] py-[8px] px-[12px] text-clientDarkCharcoal "><div className="flex justify-between items-center gap-[6px] font-bold text-[16px] leading-[16px]"><ArrowBackIcon
           className="text-clientDarkCharcoal" fontSize='medium' /><p className="font-bold text-[16px] leading-[16px]">Back</p></div></button>


          <div className=" bg-clientWhite px-[50px] mt-[50px] xl:mt-[-161px] w-full xl:mx-auto xl:max-w-[1024px] xl:px-[120px] h-auto flex flex-col justify-center rounded-[12px] [box-shadow:0px_1px_11px_0px_#00000026] relative">
            <div className="mt-[50px]">
              <h1 className="font-lejour font-normal text-[clamp(20px,_3vw,_40px)] leading-[40px] text-center ">{blogDetail[0].title?blogDetail[0].title:""}</h1>
            </div>
            <div className="text-clientNero">
              <div className="my-[30px]">
                <h2 className="text-clientNero text-justify break-words font-glacial font-bold text-[clamp(16px,_3vw,_22px)] leading-[33.6px]  ">
                {blogDetail[0].description?blogDetail[0].description:""}</h2>
              </div>
              {/* <div className="my-[30px]">
                <p className="text-justify break-words font-glacial font-normal mobile:text-[18px]  text-[24px] leading-[33.6px]">
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              </div>
              <div className="w-full mx-auto bg-white max-w-[784px] h-auto" >
                <img className="w-full h-auto m-0 rounded-none" src={BlogDetail1}></img>
              </div>
              <div className="my-[30px]">
                <p className="text-justify break-words font-glacial font-normal mobile:text-[18px]  text-[24px] leading-[33.6px]">
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              </div>
              <div className="w-full mx-auto bg-white max-w-[784px] h-auto" >
                <img className="w-full h-auto m-0 rounded-none" src={BlogDetail2}></img>
              </div> */}
              <div className="my-[30px]">
                {
                  (blogDetail[0]?.detail && blogDetail[0].detail.length > 0) ? (
                    <ul className="text-justify break-words list-decimal pl-5 font-glacial font-normal text-[clamp(16px,_3vw,_22px)] leading-[33.6px] ">
                      {blogDetail[0].detail.map((item, index) => {
                        return <li key={index}><h2 className="text-clientNero text-justify break-words font-glacial font-bold text-[clamp(16px,_3vw,_22px)] leading-[33.6px] ">{item.title}</h2>
                        <p className="text-clientNero text-justify break-words font-glacial font-normal text-[clamp(16px,_3vw,_22px)] leading-[33.6px]">
                          {item.description}
                          </p></li>
                      })}

                    </ul>
                  ) : ("")
                }

              </div>
              <div className="my-[30px]">
                <p className="text-clientNero text-justify break-words font-glacial font-bold text-[clamp(16px,_3vw,_22px)] leading-[33.6px]  ">
                {blogDetail[0].detailDesc?blogDetail[0].detailDesc:""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailSection;
