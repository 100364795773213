import React from "react";

function BlogCard({ CardData, uniqueKey,navigateBlogDetail }) {

  return (
    <>
      <div key={uniqueKey} onClick={()=> navigateBlogDetail()} className="flex flex-col justify-between w-full  mx-auto p-[15px] max-w-[386px] h-auto rounded-[7px] bg-[#FFFFFF] [box-shadow:0px_0px_12px_0px_#00000029]">
        <div className="cursor-pointer" >
          <div className="w-full mx-auto max-w-[356px] aspect-[356/200]">
            <img src={CardData.image} alt="Description" className="!rounded-none !m-0 object-cover w-full h-full" />
          </div>
          <div className="my-[20px]" >
            <h2 className="font-lejour xl:text-[25px] text-[25px]  xl:leading-[25px] leading-[25px] font-normal text-clientNero">
              {CardData.title}
            </h2>
          </div>
          <div className="mt-[20px]" >
            <p className="text-justify break-words font-glacial xl:text-[18px] text-[18px] xl:leading-[28.8px] leading-[28.8px] font-normal text-clientNero">
              {CardData.description}
            </p>
          </div>
        </div>
        <div className="mt-[20px]">
          <button className="rounded-[7px] border-[2px] border-[solid] border-primary py-[10px] px-[20px] font-glacial leading-[14px] text-clientBlack text-[14px] font-bold text-left">Read more</button>
        </div>
      </div>
    </>
  );
}

export default BlogCard;
