import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <>
      <div className='container mx-auto h-screen flex justify-center'>
				<div className='flex flex-col justify-center items-center space-y-12'>
					<CircularProgress color='secondary' size={100} />
					<div className='font-mono hidden iphone:block text-sm iphone:text-base md:text-2xl italic font-semibold text-slate-600'>
						Hang tight! We are rerouting you...
					</div>
				</div>
			</div>
    </>
  );
};

export default Loader;
	