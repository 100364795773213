import * as yup from "yup";

const passwordPattern = `(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$`;
const userNamePattern = /^[^\s]*$/;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// Define the validation schema using Yup
export const signupSchema = yup.object().shape({
    username: yup.string().required("Username is required").min(5, "Username must be at least 5 characters").max(30,"Username must be no more than 30 characters").matches(userNamePattern,"Username cannot contain spaces"),
    email: yup.string().required("Email is required").min(6,"Email must be at least 6 characters").matches(emailPattern,"Please enter a valid email"),
    password: yup.string().required("Password is required").min(8, "Password must be at least 8 characters").max(50,"Password must be no more than 50 characters").matches(passwordPattern,"Password must contain one uppercase, lowercase letter, special character and a digit"),
    firstName: yup.string().required("First name is required").min(2, "First name must be at least 2 characters").max(30,"First name must be no more than 30 characters"),
    lastName: yup.string().required("Last name is required").min(2, "Last name must be at least 2 characters").max(30,"Last name must be no more than 30 characters"),
  });

  export const loginSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });

  export const forgotSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
  });
