import React, { useEffect, useState } from "react";
import logo from "../../assets/marketing/logo.webp";
import { Link, useLocation } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { CONTACT_US_CALENDLY_URL } from "../../utils/constant/constants";

const Header = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  const [scrollDirection, setScrollDirection] = useState("up");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const RouteData = [
    { name: "Services", route: "/services" },
    { name: "About us", route: "/about-us" },
    { name: "Contact us", route: "/contact" },
    { name: "Blog", route: "/blog" },
  ];

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollDirection(currentScrollY > lastScrollY ? "down" : "up");
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full z-10 transition-transform duration-1000 ease-in-out ${
        scrollDirection === "up"
          ? "translate-y-0 fixed top-0"
          : "translate-y-[-100%] absolute"
      }`}
    >
      <header className="font-lejour [box-shadow:0px_1px_0px_0px_#0000000D] bg-white text-clientNero h-[80px] max-w-[1280px] mx-auto px-8 tablet:px-0 mobile:px-0 z-10">
        <div className="flex justify-between items-center h-full">
          <Link to="/" className="flex items-center">
            <img className="w-40 h-auto" alt="Janerothe Logo" src={logo} />
          </Link>

          <nav className="ml-5">
            <ul className="mobile:hidden tablet:hidden flex space-x-6">
              {RouteData.map((item, index) => (
                (item.route==='/contact') ? (<li key={index}>
                  <PopupButton
                    url={CONTACT_US_CALENDLY_URL}
                    rootElement={document.getElementById('root')}
                    text="Contact Us"
                    // textColor="#ffffff"
                    // color="#00a2ff"
                    className="font-lejour text-[16px] font-normal leading-[28.8px]" // Optional: add your own class for styling
                  />
                </li>) :
                
               ( <li key={index}>
                  <Link
                    to={item.route}
                    style={{
                      color: pathname === item.route ? "#CC1DB9" : "#202020",
                    }}
                    className="font-lejour text-[16px] font-normal leading-[28.8px]"
                  >
                    {item.name}
                  </Link>
                </li>)
              ))}
            </ul>

            <div className="hidden mobile:block tablet:block">
              <button
                onClick={toggleMobileMenu}
                className="text-gray-800 focus:outline-none pr-[5px]"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
          </nav>
        </div>

        {isMobileMenuOpen && (
          <div className="hidden tablet:block mobile:block">
            <ul className="flex flex-col space-y-2 p-4 bg-white shadow-md">
              {RouteData.map((item, index) => (
                (item.route==='/contact') ? (<li key={index}>
                  <PopupButton
                    url={CONTACT_US_CALENDLY_URL}
                    rootElement={document.getElementById('root')}
                    text="Contact Us"
                    // textColor="#ffffff"
                    // color="#00a2ff"
                    className="font-lejour text-[16px] font-normal leading-[28.8px]" // Optional: add your own class for styling
                  />
                </li>) :
                
               ( <li key={index}>
                  <Link
                    to={item.route}
                    style={{
                      color: pathname === item.route ? "#CC1DB9" : "#202020",
                    }}
                    className="font-lejour text-[16px] font-normal leading-[28.8px]"
                  >
                    {item.name}
                  </Link>
                </li>)
              ))}
            </ul>
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
