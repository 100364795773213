import React, { useEffect, useState } from "react";
import ForgetPasswordBg from "../../assets/marketing/ForgetPasswordBg.webp";
import logo from "../../assets/marketing/logo.webp";
import UserIcon from "../../assets/marketing/UserIcon.svg";
import { AuthenticationField } from "../CommonComponents/CustomInputField";
import { AuthenticationButton } from "../CommonComponents/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { forgotSchema } from "../../utils/validation/validationSchema";
import SublyApi from "../../helpers/Api";
import { addErrors, clearErrors } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function ForgetPasswordSection() {
	const dispatch = useDispatch();
  const history = useHistory();
  const apiError = useSelector((st) => st.user.errors);

	const [open, setOpen] = useState(false); // state for confirmation modal

	// sets error to null when the component first mounts and when it dismounts.
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors()); // dismount
		};
	}, []);

  const { register, handleSubmit, formState: { errors },reset } = useForm({
    resolver: yupResolver(forgotSchema),
    mode: 'onChange',
  });

  //submits the form data to the Api to send user email
	const onSubmit = async (data) => {
		try {
			dispatch(clearErrors());
			await SublyApi.forgotPassword(data.username);
      reset();
			setOpen(true);
		} catch (err) {
			dispatch(addErrors(err));
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

  return (
    <div
      className="flex  w-full h-[100vh] 
    mobile:block
    mobile:h-full"
    >
      <div
        className="w-[36%] h-[100vh] overflow-y-auto p-[50px]
       tablet:w-full
       tablet:p-[20px]
       mobile:w-full
       mobile:h-full
       mobile:p-[10px]"
      >
        <div
          className="w-[258px] h-auto ml-[-17px]"
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={logo} alt="logo" className="w-full h-full m-[0]"></img>
        </div>

        <div
          className="w-full h-auto hidden mt-[25px] 
          tablet:block
          mobile:block
          "
        >
          <img
            src={ForgetPasswordBg}
            alt="ForgetPasswordBg"
            className=" w-full h-full m-[0] object-cover rounded-none
           tablet:w-full tablet:h-auto
           mobile:w-full mobile:h-auto"
          ></img>
        </div>

        <div className="mt-[50px]">
          <h1 className="font-glacial text-[45px] font-bold leading-[45px] text-clientNero m-0">
            Forgot password?
          </h1>
        </div>

        <div className="mt-[25px]">
          <h1 className="font-glacial text-[22px] font-normal leading-[22px] text-clientDimGrey m-0">
            Forgot your password
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[25px]">
          <AuthenticationField
            type="text"
            placeholder="User email"
            icon={UserIcon}
            register={register} 
            errors={errors}
            name="username"
          />
        </div>
        <div className='text-red-600 mt-3 '>
							{apiError.map((e, indx) => (
								<div key={indx}>
									<small>ERROR: {e}</small>
								</div>
							))}
						</div>
        <AuthenticationButton type={"submit"} label="Email Instructions" />
       </form>
        <div className="w-full flex justify-center  items-center mt-[25px]">
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push("/login");
            }}
          >
            <p className="font-glacial text-[20px] italic font-normal leading-[20px] text-clientNero">
              Back To Login
            </p>
          </div>
        </div>
      </div>

      <div
        className="w-[64%] h-full  
       tablet:hidden
       mobile:hidden
       "
      >
        <img
          src={ForgetPasswordBg}
          alt="ForgetPasswordBg"
          className=" w-full h-full m-[0] object-cover rounded-none"
        ></img>
      </div>
      <Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{"Email Sent!"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						An email has been sent to your provided email address with
						instructions to reset your password.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Sounds Good!
					</Button>
				</DialogActions>
			</Dialog>
    </div>
  );
}

export default ForgetPasswordSection;
