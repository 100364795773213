import AboutPageBgImg from "../../assets/marketing/AboutPageBgImg.webp";
import FounderImg from "../../assets/marketing/1.webp";
import JourneyImg from "../../assets/marketing/journeyImgNew.webp";
import OurTeamImg from "../../assets/marketing/teamImageNew.webp";
import MissionImg from "../../assets/marketing/2-_2_.webp";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="max-w-[1280px] mx-[auto] flex flex-col justify-center">
        <div data-aos="fade-up" className="max-w-[1280px] mx-[auto] h-auto pt-[105px] pr-[25px] pl-[25px] relative  mobile:pl-[10px] mobile:pr-[10px]">
          <img
            src={AboutPageBgImg}
            alt="ServiceBg"
            className="w-full h-auto rounded-[35px] m-[0]"
          ></img>

          <div
            className="absolute bottom-0 mb-[80px] ml-[80px] 
         tablet:mb-[40px] tablet:ml-[40px]
         mobile:mb-[20px] mobile:ml-[20px]
        "
          >
            <h1
              className="font-lejour  text-[clamp(35px,_3vw,_70px)] font-normal leading-[70px] text-left m-0 text-[rgba(255,_255,_255,_1)] uppercase
            mobile:leading-[35px]"
            >
              About Us
            </h1>
            {/* <div className="w-[65%] tablet:w-[70%] mobile:w-[90%]">
              <p
                className="font-glacial text-[clamp(15px,_3vw,_20px)] font-bold leading-[32px] text-left m-0 text-[rgba(255,_255,_255,_1)] uppercase
              tablet:leading-[24px]
              mobile:leading-[16px]"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div> */}
          </div>
        </div>

        <div className="w-full max-w-7xl mx-auto ">
          <div className="w-full items-start max-w-[1024px] my-[70px] mobile:my-[25px] xl:mx-[128px] md:px-[80px] sm:px-[40px] mobile:px-[10px] grid tablet:grid-cols-[40%_auto] tablet:px-[40px] grid:gap-[30px] sm:grid-cols-[40%_auto]  gap-6 lg:gap-[70px] md:gap-[50px] sm:gap-[30px] ">
            <div className="w-full mx-auto sm:justify-self-center ">
              <img
                src={FounderImg}
                alt="Founder"
                className="w-full m-0 p-0 h-auto rounded-lg aspect-square"
              />
            </div>
            <div className="w-full  flex flex-col justify-center place-self-center order-last mobile:order-first ">
              <h2 className="font-lejour xl:text-[25px] text-[25px]  xl:leading-[25px] leading-[25px] font-normal text-clientNero">
                Our Foundation
              </h2>
              <p className="text-justify break-words font-glacial xl:text-[18px] text-[18px] xl:leading-[28.8px] leading-[28.8px] font-normal  mt-2 text-clientNero">
                The Jane Rothe Co., inspired by the values and legacy of Amy
                Borh's grandmother, Jane Rothe, was founded to bring a personal
                touch to real estate marketing. Rooted in the tradition of a
                family business, our company embodies the familial warmth and
                dedication that Jane valued above all. Amy's extensive
                experience in counseling, social work with a business focus, and
                roles in public service and real estate drives our innovative
                approach.
              </p>
            </div>
          </div>
          {/* </div> */}

          {/* </div> */}
          <div className="w-full max-w-[1280px] mx-auto  bg-clientWhiteSmoke mobile:my-[10px] mobile:py-[20px]  tablet:my-[10px] tablet:py-[20px]">
            <div className="max-w-[1152px]  grid tabletMore:grid-cols-2  grid-cols-1  gap-[30px]">
              <div className="w-full max-w-[640px] tabletMore:order-first tablet:mx-auto mobile:mx-auto order-last">
                <img
                  src={JourneyImg}
                  alt="Journey Image"
                  className="w-full m-0 p-0  h-auto  rounded-lg"
                />
              </div>
              <div className="flex flex-col justify-center my-auto ml-[20px]">
                <h2 className="font-lejour text-[25px] font-normal leading-[25px] text-left text-clientNero">
                  Our Journey
                </h2>
                <p className="text-justify break-words font-glacial text-[18px] font-normal leading-[28.8px] mt-2 text-clientNero">
                  From navigating complex dynamics in Mayor Garcetti's office to
                  strategizing as a federal consultant, Amy's diverse background
                  has uniquely positioned her to redefine real estate marketing.
                  Her transition to real estate was a natural progression, where
                  she integrated psychological insights with market analytics to
                  enhance transaction strategies.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full max-w-[1280px] mx-auto mt-[10px] flex justify-end ">
            <div className="max-w-[1152px] grid tabletMore:grid-cols-2  grid-cols-1  gap-[30px]">
              <div className="flex flex-col  justify-center my-auto xl:pl-[0px] pl-[10px] ">
                <h2 className="font-lejour text-[25px] font-normal leading-[25px] text-left text-clientNero">
                  Our Team
                </h2>
                <p className="text-justify break-words font-glacial text-[18px] font-normal leading-[28.8px] mt-2 text-clientNero">
                  Under Amy’s leadership, The Jane Rothe Co. benefits from a
                  team of dedicated professionals. Being a family business, we
                  extend this familial approach to our team, treating each
                  member and client as part of our extended family, which
                  ensures the delivery of outstanding results. Together, we work
                  to merge traditional marketing techniques with innovative
                  strategies, aiming to empower real estate professionals toward
                  unprecedented success.
                </p>
              </div>
              <div className="w-full max-w-[640px] mx-auto">
                <img
                  src={OurTeamImg}
                  alt="Journey Image"
                  className="w-full m-0 p-0  h-auto  rounded-lg"
                />
              </div>
            </div>
          </div>
          <div className="w-full max-w-[1280px] mt-[60px] mb-[70px] flex justify-center ">
            <div className="max-w-[1024px] grid grid-cols-[49.21%_auto] mobile:grid-cols-1 gap-[70px]">
              <div className="flex flex-col justify-center my-auto xl:ml-[0px] ml-[20px]  ">
                <h2 className="font-lejour text-[25px] font-normal leading-[25px] text-left text-clientNero">
                  Our Mission
                </h2>
                <p className="text-justify break-words font-glacial text-[18px] font-normal leading-[28.8px] mt-2 text-clientNero">
                  We are committed to transforming the real estate landscape by
                  crafting tailored marketing strategies that resonate deeply
                  with clients, driving their business beyond traditional goals.
                  We honor Jane Rothe’s legacy of family and innovation by
                  continuing to push boundaries and innovate within the
                  industry.
                </p>
              </div>
              <div className="w-full mx-auto justify-center max-w-[450px] mobile:px-[10px]">
                <img
                  src={MissionImg}
                  alt="Mission Image"
                  className="w-full m-0 p-0 h-auto rounded-lg aspect-square"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
