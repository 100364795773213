import React, { useEffect, useRef, useState } from "react";
import CheckBoxImg from "../../../assets/marketing/CheckBoxImg.webp";
import WorkingHandImg from "../../../assets/marketing/WorkingHandImg.webp";
import WorkingHandVid from "../../../assets/marketing/Homepage-SignUpforEmail.mp4";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPlayer from "react-player";
const SignUpThroughHome = () => {
  const history = useHistory();
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true); // Play when the section is visible
          } else {
            setIsPlaying(false); // Pause when out of view
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the video section is visible
      }
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  },[]);

  useEffect(() => {
    //  const timer = setTimeout(() => setIsPlaying(true), 100);
  // return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="mt-[40px] w-full h-auto rounded-[10px] border-[1px] border-[solid] border-clientMercury pt-[5px]  pb-[5px] px-[30px] flex justify-between items-center
           mobile:block
           mobile:mt-[20px]
           mobile:px-[5px]
          "
    >
      <div className="grid md:grid-cols-2 ">
      <div
        className="flex justify-between flex-col mt-[30px] mb-[30px]
             mobile:w-full"
      >
        <h1 className="font-lejour text-[30px] font-normal leading-[30px] text-left m-0 text-clientDarkCharcoal">
          Sign up for Jane Rothe Updates
        </h1>

        <div className="mt-[40px]">
          <p className="font-glacial text-[18px] font-normal leading-[18px] m-0 text-clientDarkCharcoal">
            Email address <span className="text-[red] ">*</span>
          </p>
        </div>

        <input
          className="font-glacial w-[90%] h-[50px] rounded-[7px] border-[1px] border-[solid] border-clientLightGrey pl-[15px] mt-[10px]
                mobile:w-full
                "
          type="text"
          placeholder="Enter your email address"
        ></input>

<div className="mt-[40px]">
          <p className="font-glacial text-[18px] font-normal leading-[18px] m-0 text-clientDarkCharcoal">
            First name <span className="text-[red] ">*</span>
          </p>
        </div>

        <input
          className="font-glacial w-[90%] h-[50px] rounded-[7px] border-[1px] border-[solid] border-clientLightGrey pl-[15px] mt-[10px]
                mobile:w-full
                "
          type="text"
          placeholder="Enter your first name"
        ></input>

        <div className="mt-[40px]">
          <p className="font-glacial text-[18px] font-normal leading-[18px] m-0 text-clientDarkCharcoal">
            Last Name <span className="text-[red] ">*</span>
          </p>
        </div>

        <input
          className="font-glacial w-[90%] h-[50px] rounded-[7px] border-[1px] border-[solid] border-clientLightGrey pl-[15px] mt-[10px]
                mobile:w-full
                "
          type="text"
          placeholder="Enter your last name"
        ></input>
        
        <button
          className="mt-[30px] w-[180px] h-[50px] rounded-[7px] border-[2px] border-primary border-[solid] flex justify-center items-center
                mobile:w-full"
          onClick={() => {
            history.push("/login");
          }}
        >
          <div>
            <p className="font-glacial text-[18px] font-bold  leading-[18px] m-0 text-clientDarkCharcoal">
              Sign Up
            </p>
          </div>
        </button>

        <div className="flex justify-center items-center mt-[30px]">
          <img
            src={CheckBoxImg}
            alt="CheckBoxImg"
            className="w-[22px] h-[22px] ml-[0]"
          ></img>
          <div>
            <p className="font-glacial text-[18px] font-normal  leading-[22.5px] m-0 text-clientDarkCharcoal break-words">
              By entering your email address, you are accepting our{" "}
              <span className="text-primary">Terms   & Conditions</span> and{" "}
              <span className="text-primary">Privacy & Cookie Policy.</span>
            </p>
          </div>
        </div>
      </div>

      <div ref={playerRef}  className="max-w-[100%] !rounded-[10px] overflow-hidden w-full my-auto flex items-center justify-center">
        <ReactPlayer width="100%" 
        height="100%" 
        playing={isPlaying}
        loop={true}
        controls 
        url={WorkingHandVid}  />
       
      </div>
      </div>
    </div>
  );
};

export default SignUpThroughHome;
