import React from "react";
import Logo2 from "../../assets/marketing/Logo2.svg";
import InstagramImg from "../../assets/marketing/InstagramImg.svg";
import FacebookImg from "../../assets/marketing/FacebookImg.svg";
import GoogleImg from "../../assets/marketing/GoogleImg.svg";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { CONTACT_US_CALENDLY_URL } from "../../utils/constant/constants";
import SocialLinks from "./SocialLinks";

const LandingFooter = () => {
  return (
    <div>
      <div
        className="max-w-[1280px] mx-auto h-[271px] bg-clientBlack flex justify-between items-center flex-col pt-[20px] pb-[20px] mt-[40px]
       mobile:pl-[5px] mobile:pr-[5px]"
      >
        <img
          src={Logo2}
          alt="logo"
          className="w-[258px] h-[56.2px] m-[0] rounded-none"
        ></img>

        <div
          className="w-[461px] h-[50px] rounded-[6px]  border-[2px] border-[solid] border-clientSmokyBlack flex justify-between items-center pl-[20px] pr-[20px]
         mobile:w-full"
        >
          <div>
            <p className="font-lejour ext-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <Link to="services">  SERVICES  </Link>
            </p>
          </div>

          <div>
            <p className="font-lejour ext-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">

              <Link to="about-us" >  ABOUT US  </Link>
            </p>
          </div>

          <div>
            <p className="font-lejour ext-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <PopupButton
                url={CONTACT_US_CALENDLY_URL}
                rootElement={document.getElementById('root')}
                text="Contact Us"
              // textColor="#ffffff"
              // color="#00a2ff"
              />
              {/* <Link to="contact-us" >   CONTACT US </Link> */}
            </p>
          </div>

          <div>
            <p className="font-lejour ext-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <Link to="blog" >   BLOG </Link>

            </p>
          </div>
        </div>

        <div className="w-[190px] h-[50px] flex justify-between items-center ">
          {SocialLinks.map((item, index) => (
            <a href={item.link} key={index} rel="noopener noreferrer" target="_blank">
              <div>
                <img
                  src={item.image}
                  alt={item.alt}
                  className="w-[50px] h-[50px] m-[0]"
                ></img>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div
        className="max-w-[1280px] mx-auto h-[50px] bg-clientWhite flex justify-between  items-center pl-[100px] pr-[100px]
        tablet:pl-[20px] tablet:pr-[20px]
        mobile:pl-[5px] mobile:pr-[5px]"
      >
        <div>
          <p
            className="font-glacial text-[20px] tablet:text-[15px] font-normal leading-[20px] text-left m-0
            mobile:text-[10px]"
          >
            All rights reserved.
          </p>
        </div>

        <div>
          <p
            className="font-glacial text-[20px] tablet:text-[15px] font-normal leading-[20px] text-left m-0
           mobile:text-[10px]"
          >
            © 2024 Jane Rothe Co.
          </p>
        </div>

        <div>
          <p
            className="font-glacial text-[20px] tablet:text-[15px] font-normal leading-[20px] text-left m-0
           mobile:text-[10px]"
          >
            <span className=''>
						Made with
						<span className='mx-1 text-red-500'>❤️</span> from California
					</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
