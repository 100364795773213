import React, { useEffect } from "react";
import LakeSideVilla from "../../assets/marketing/LakeSideVilla.webp";
import logo from "../../assets/marketing/logo.webp";
import checkBoxImg from "../../assets/marketing/CheckBoxImg.webp";
import UserIcon from "../../assets/marketing/UserIcon.svg";
import LockImg from "../../assets/marketing/LockImg.svg";
import { AuthenticationField } from "../CommonComponents/CustomInputField";
import { AuthenticationButton } from "../CommonComponents/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema } from "../../utils/validation/validationSchema";
import { clearErrors, userLogin } from "../../store/user";
import Loader from "./Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function SignInSection() {
  const history = useHistory();
  const dispatch = useDispatch();
  const apiError = useSelector((st) => st.user.errors);
  const loading = useSelector((st) => st.user.pending);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });
  
  const onSubmit = (data) => {
    dispatch(userLogin(data));
  };

  useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

  if (loading)
		return (
			<Loader></Loader>
		);
  return (
    <div
      className="flex  w-full h-[100vh] 
      mobile:block
      mobile:h-full"
    >
      <div
        className="w-[36%] h-[100vh] overflow-y-auto p-[50px]
       tablet:w-full
       tablet:p-[20px]
       mobile:w-full
       mobile:h-full
       mobile:p-[10px]"
      >
        <div
          className="w-[258px] h-auto ml-[-17px]"
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={logo} alt="logo" className="w-full h-full m-[0]"></img>
        </div>

        <div
          className="w-full h-auto hidden mt-[25px] 
          tablet:block
          mobile:block
          "
        >
          <img
            src={LakeSideVilla}
            alt="LakeSideVilla"
            className=" w-full h-full m-[0] object-cover rounded-none
           tablet:w-full tablet:h-auto
           mobile:w-full mobile:h-auto"
          ></img>
        </div>

        <div className="mt-[50px]">
          <h1 className="font-glacial text-[45px] font-bold leading-[45px] text-clientNero m-0">
            Sign In
          </h1>
        </div>

        <div className="mt-[25px]">
          <h1 className="font-glacial text-[22px] font-normal leading-[22px] text-clientDimGrey m-0">
            Sign in to your Account!
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[25px]">
          <AuthenticationField
            name="username"
            placeholder="User name"
            icon={UserIcon}
            register={register} 
            errors={errors}
          />

          <AuthenticationField
            type="password"
            placeholder="Password"
            icon={LockImg}
            name="password"
            register={register} 
            errors={errors}
          />
        </div>
        <div className='text-red-600 mt-3 '>
							{apiError.map((e, indx) => (
								<div key={indx}>
									<small>ERROR: {e}</small>
								</div>
							))}
						</div>
        <div className="flex justify-between items-center mt-[17px]">
          <div className="flex justify-between items-center">
            <img
              src={checkBoxImg}
              alt="checkBoxImg"
              className="w-[15px] h-[15px] m-[0]"
            ></img>
            <div className="ml-[10px]">
              <p className="font-glacial text-[16px] font-normal leading-[16px] text-clientNero m-0">
                Remember me
              </p>
            </div>
          </div>

          <div className="flex justify-center  items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push("/forgot-password");
              }}
            >
              <p className="font-glacial text-[16px] font-normal leading-[16px] text-clientLightYellow m-0">
                Forgot Password?
              </p>
            </div>
          </div>
        </div>
        <AuthenticationButton type={"submit"} label="Sign In" />
        </form>
        <div className="w-full flex justify-center  items-center mt-[25px]">
          <div>
            <p className="font-glacial text-[14px] font-bold leading-[14px] text-clientDimGrey">
              Don't have an account?{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                Sign Up
              </span>
            </p>
          </div>
        </div>
      </div>

      <div
        className="w-[64%] h-full  
        tablet:hidden
        mobile:hidden
        "
      >
        <img
          src={LakeSideVilla}
          alt="LakeSideVilla"
          className=" w-full h-full m-[0] object-cover rounded-none"
        ></img>
      </div>
    </div>
  );
}

export default SignInSection;
