import JustList from "../../../assets/marketing/JustListed-Just-Sold.webp";
import SittingImg from "../../../assets/marketing/Internet-Ads.webp";
import SocialMediaImg from "../../../assets/marketing/Social-Media-Service.webp";
import EmailMediaImg from "../../../assets/marketing/Email-Marketing.webp";
import DirectMark from "../../../assets/marketing/Direct-Mail.webp";

export const ServiceCardData = [
  // {
  //   id: 1,
  //   isButton: 0,
  //   image: KitchenArea,
  //   headingStart: "GOOGLE ADS MANAGEMENT",
  //   headingMiddle: "$175",
  //   headingEnd: "MONTH +AD SPEND",
  //   listingPint: [
  //     "Google PPC & Local Service Ads Management",
  //     "Google Ads Account Setup",
  //     "Ongoing Ad Optimization",
  //     "Keyword Research",
  //     "Manual Bidding",
  //     "Geo-Targeting",
  //     "Search Term Audit",
  //     "Selection of Keyword Match Types",
  //     "Conversion Tracking",
  //     "Service-Category Targeting",
  //     "Ad Scheduling",
  //   ],
  // },
  {
    id:1,
    isButton: 0,
    image: SocialMediaImg,
    headingStart: "SOCIAL MEDIA MARKETING",
    headingMiddle: "$395",
    headingEnd: "MONTH",
    listingPint: [
      "Brand Building",
      "Increase Following",
      "Increase Engagement",
      "Increase Traffic",
      "Personalized Images",
      "Posts 3x week",
    ],
  },
  {
    id: 2,
    isButton: 0,
    image: SittingImg,
    headingStart: "DIGITAL BILLBOARDS - RETARGETING",
    headingMiddle: "$150",
    headingEnd: "MONTH",
    listingPint: [
      "Strategically Placed Internet Ads",
      "Banner Advertising",
      "Mobile Advertising",
      "Target Your Sphere of Influence",
      "Target Lookalike Audiences",
      "Target Geo-Specific Audiences",
      "Personalized Designs",
      "Software Setup & Management",
      "Ad Optimization",
    ],
  },
  {
    id:3,
    isButton: 0,
    image: JustList,
    headingStart: "LISTING AND POST-CLOSE EXECUTION",
    headingMiddle: "$395",
    headingEnd: "MONTH",
    listingPint: [
      "Listing - We help you market your listings and provide you with material that impresses your clients.",
      "Closing - We manage multiple closing tasks, including reputation management and client nurturing.",
    ],
  },

  {
    id:4,
    isButton: 0,
    image: DirectMark,
    headingStart: "DIRECT MAIL MARKETING STARTING",
    headingMiddle: "$195",
    headingEnd: "MONTH",
    listingPint: [
      "Graphic Design",
      "Campaign Management",
      "Hard Mailers 12, 6, or 4x Yearly",
      "Mailers Sent to Database",
    ],
  },
  {
    id:5,
    isButton: 0,
    image: EmailMediaImg,
    headingStart: "EMAIL MARKETING",
    headingMiddle: "$125",
    headingEnd: "MONTH",
    listingPint: [
      "24 Emails",
      "Send to Database 1-2x a Month",
      "Option to Send up to 4x a Month",
      "Branded Email Templates",
      "Software Setup & Management",
    ],
  },

  

];
