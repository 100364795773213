import React from "react";
import MainLayout from "../../layouts/MainLayout";
import AboutSection from "../../section/AboutSection";
const AboutPage = () => {
  return (
    <MainLayout>
      <AboutSection />
    </MainLayout>
  );
};

export default AboutPage;
