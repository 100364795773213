import SearchImg from "../../assets/marketing/ri_search-line.svg";
import BlogCard from "../pages/blog/BlogCard";
import { BlogCardData } from "../pages/blog/BlogCardData";
import BlogDetailBgImg from "../../assets/marketing/blog_detail_bg.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlogSection = () => {
	const history = useHistory();
  const navigateBlogDetail = (item) => {
		history.push(`${'/blog/'+item.id}`);
	};
  return (
    <>
      <div className="container max-w-[1280px] mx-auto mt-[120px] ">
        <div className="my-[20px] text-center text-[clamp(35px,_3vw,_70px)] font-lejour font-normal leading-[70px] mobile:leading-[35px] text-clientNero  ">
          Blog
        </div>
        
        <div className="w-full px-[30px] grid lg:!grid-cols-3 md:grid-cols-3 sm:grid-cols-2  gap-[30px]">
          {BlogCardData.length > 0 && BlogCardData.map((item, index) => {
            return <BlogCard CardData={item} uniqueKey={index} navigateBlogDetail={()=>navigateBlogDetail(item)}></BlogCard>
          })}
        </div>
        <div className="w-full mt-[50px]">
          <div className="w-full h-auto max-h-[370px]">
            <img className="m-0 rounded-none object-cover w-full h-auto max-h-[370px]" src={BlogDetailBgImg}></img>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default BlogSection;
