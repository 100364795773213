import React from "react";
import MainLayout from "../../layouts/MainLayout";
import ServiceSection from "../../section/ServiceSection";
const ServicePage = () => {
  return (
    <MainLayout>
      <ServiceSection />
    </MainLayout>
  );
};

export default ServicePage;
