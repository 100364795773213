import BlogImg1 from "../../../assets/marketing/blog1.webp";
import BlogImg2 from "../../../assets/marketing/blog2.webp";
import BlogImg3 from "../../../assets/marketing/blog3.webp";

import SliderImg1 from "../../../assets/marketing/blog_slider.webp";
import SliderImg2 from "../../../assets/marketing/blog_slider1.webp";
import SliderImg3 from "../../../assets/marketing/blog_silder2.webp";

export const BlogCardData = [
  {
    id:1,
    image: BlogImg1,
    title: "What to Do When Business Slows Down",
    description: "In real estate, market fluctuations are inevitable. These slowdowns are an opportunity to invest time in areas that can amplify your success when business picks up again. Here’s a deep dive into how to stay productive, engaged, and ready for the next market upturn:",
    detail:[
      {title:"Reconnect with Your Network",description:"Slow periods are ideal for revisiting past client relationships, reaching out to colleagues, and engaging with industry connections. This isn’t about pitching new business; it’s about showing genuine interest. Send personalized emails, make phone calls, or arrange casual coffee meetups. Discuss their long-term real estate goals or share valuable insights. When clients feel supported beyond the transaction, they’re more likely to refer you to friends and family or return to you for their next real estate move."},
      {title:"Schedule Coffee and Lunch Dates",description:"Relationships are built face-to-face, and slow times give you the freedom to make these in-person meetings happen. Consider setting up coffee or lunch dates with other local agents to exchange market insights. It’s equally valuable to connect with business owners, lenders, and others in your area who can provide leads, share referrals, and even partner on community events."},
      {title:"Show Up at Industry and Community Events",description:"Be visible in the community by attending local events, charity fundraisers, or city council meetings. People recognize and trust agents who are involved and present. Plus, these events provide a perfect setting for expanding your network naturally. Strike up conversations, share your expertise, and listen to others’ experiences to learn about emerging trends and opportunities."},
      {title:"Engage in New Social Settings",description:"Explore places where you can meet potential clients who are outside your typical circle. Volunteering, joining local clubs, or attending seminars about related industries can expose you to different groups and prospective clients. Diversifying your network can be crucial in expanding your reach."},
      {title:"Stay Consistent and Patient",description:"Real estate is a long game, and the agents who continue their marketing efforts and maintain visibility through slow periods reap the rewards when business rebounds. Stay on top of local real estate trends, nurture your current relationships, and keep putting yourself out there."},
    ],
    detailDesc:"Remember, slow periods are as much an opportunity as a challenge. By using this time effectively, you’re setting up for future success and growth when the market picks up again.",
    seo:{
      "primaryKeyword":"Real estate slow periods, keeping momentum in real estate, real estate networking during slow times",
      "secondaryKeyword":"productive downtime for real estate agents, real estate client follow-up, real estate market slowdown strategies",
      "title":"5 Effective Ways Real Estate Agents Can Stay Productive During Slow Periods",
      "metaDesc":"Real estate slow period? Learn how to stay productive, nurture client relationships, and build your network during quieter market times.",
      "imgAlt":"real estate agent networking during slow period, tips for real estate productivity"}
  }

];

export const blogDetailList = [
  "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
  "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ",
  "Content here, content here', making it look like readable English.",
  "Many desktop publishing packages and web page editors now use",
  "Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
  "Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
]