import React, { useEffect, useState } from "react";
import ServiceBg from "../../assets/marketing/ServicesBgImg.webp";
import DrawingRoom from "../../assets/marketing/DrawingRoom.webp";
import DrawingRoom2 from "../../assets/marketing/DrawingRoom2.webp";
// import VillaImg from "../../assets/marketing/VillaImg.svg";
import SpImage from "../../assets/marketing/spimage.webp";

import ServiceCard from "../pages/ServicePage/ServiceCard";
import { ServiceCardData } from "../pages/ServicePage/ServiceCardData";
import { PopupButton } from "react-calendly";
import { CONTACT_US_CALENDLY_URL } from "../../utils/constant/constants";
import AOS from 'aos';
import 'aos/dist/aos.css';

function ServiceSection() {
  // State to track which card's content is expanded
  const [expandedCard, setExpandedCard] = useState(null);

  // Toggle function to show/hide content
  const toggleReadMore = (id) => {
    setExpandedCard(expandedCard === id ? null : id);
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="pt-[80px]">
      <div data-aos="fade-up" className="max-w-[1280px] mx-[auto] h-auto p-[25px] relative mobile:p-[10px]">
        <img
          src={ServiceBg}
          alt="ServiceBg"
          className="w-full h-auto rounded-[35px] m-[0]"
        ></img>

        <div
          className="absolute bottom-0 mb-[80px] ml-[80px] 
         tablet:mb-[40px] tablet:ml-[40px]
         mobile:mb-[20px] mobile:ml-[20px]
        "
        >
          <h1
            className="font-lejour text-[clamp(35px,_3vw,_70px)] font-normal leading-[70px] text-left m-0 text-clientWhite
           mobile:leading-[35px]"
          >
            Services
          </h1>
          <div className="w-[65%] tablet:w-[70%] mobile:w-[90%]">
            <p
              className="font-glacial text-[clamp(16px,_3vw,_20px)]  font-bold leading-[32px] text-left m-0 text-clientWhite uppercase
               tablet:text-[15px]
               tablet:leading-[24px]
               mobile:text-[10px]
               mobile:leading-[16px] "
            >
              all services are monthly subscriptions. THE Monthly cost does not
              include THE hard costs of marketing.
            </p>
          </div>
        </div>
      </div>

      <div
        className="max-w-[1280px] mx-auto h-auto py-[50px] px-[128px]  flex justify-between items-center 
       tablet:px-[25px]
        mobile:px-[10px]
        mobile:block
       mobile:p-[10px]"
      >
        <div
          className="w-[36%] h-auto mr-[10px]
          tablet:w-[50%]
         mobile:w-full
         mobile:mb-[10px]
        "
        >
          <img
            src={DrawingRoom}
            alt="DrawingRoom"
            className="w-full h-full m-[0] rounded-[4px] "
          ></img>
        </div>

        <div
          className=" h-auto
          tablet:w-[50%]
          mobile:w-full"
        >
          <h1
            className="font-lejour  text-[clamp(18px,_3vw,_22px)] font-normal leading-[39.6px] text-left mb-[20px] text-clientNero uppercase
            mobile:mb-[10px]"
          >
            The Premier Package -{" "}
            <span className="font-glacial text-[clamp(18px,_3vw,_22px)] font-bold leading-[26.4px] text-left m-0 text-clientPink uppercase">
              $850
            </span>
            <span className="font-bold">/</span>month
          </h1>

          <li className="font-glacial  text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Business Consultation & Growth Strategies
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Marketing Design & Execution:
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Email Marketing
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Social Media Marketing
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Direct Mail Marketing
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Digital Ads
          </li>
          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Listing Marketing
          </li>
          <div className="ml-[30px]">
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Listing Flyers & Social Media
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Ads
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Client Letter
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Property Sites, etc.
            </li>
          </div>

          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Post-Close Marketing
          </li>
          <div className="ml-[30px]">
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Social Media
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Client Letter
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Request for Review
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Client follow up
            </li>
            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              CRM Management
            </li>
          </div>

          <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
            Additional design work & more!
          </li>

          <div className="mt-[20px]">
            <p className="font-glacial text-[clamp(16px,_3vw,_18px)] font-bold leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              *Package price is for a single agent. Price increases for teams of
              2+*
            </p>
          </div>
          <div className="mt-[20px] mobile:text-center">
          <PopupButton className="p-[10px] rounded-[8px] bg-primary font-glacial text-[20px] font-bold leading-[20px] text-clientWhite hover:bg-opacity-80" url={CONTACT_US_CALENDLY_URL} rootElement={document.getElementById('root')}  text="Reach out to discuss" />
          </div>
        </div>
      </div>

      <div
        className="max-w-[1280px] mx-auto h-auto bg-clientWhiteSmoke py-[50px] px-[128px]
        tablet:px-[25px]
        mobile:px-[10px]
       mobile:p-[10px]"
      >
        <div className="w-full text-center mr-[10px] mobile:mb-[20px]">
          <p className="font-glacial text-[clamp(25px,_3vw,_30px)] font-bold leading-[45px] m-0 text-clientNero">
            We execute for you. Don’t worry about new marketing trends. Focus on
            selling while we handle your marketing operations.
          </p>
        </div>

        <div
          className="w-full h-auto flex justify-between items-center mt-[50px]
          mobile:block mobile:mt-[10px]"
        >
          <div
            className="w-[60%] h-auto 
            tablet:w-[50%]
           mobile:w-full"
          >
            <h1 className="font-lejour text-[clamp(18px,_3vw,_22px)] font-normal leading-[39.6px] text-left mb-[20px] text-clientNero uppercase">
              Brokerage Marketing and Strategy-
              <span className="font-glacial text-[clamp(18px,_3vw,_22px)] font-bold leading-[26.4px] text-left m-0 text-clientPink uppercase">
                $1,650
              </span>
              <span className="font-bold">/</span>month +{" "}
              <span className="font-glacial text-[clamp(18px,_3vw,_22px)] font-bold leading-[26.4px] text-left m-0 text-clientPink uppercase">
                $150
              </span>
              <span className="font-bold">/</span>Consultation Fee
            </h1>

            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Best for boutique brokerages who are looking to grow
            </li>

            <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
              Comprehensive Marketing Director Services:
            </li>

            <div className="ml-[30px]">
              <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
                Evaluation and assessment of current marketing materials.
              </li>
              <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
                Develop new marketing content, including emails, social media,
                direct mail, and internet ads.
              </li>
              <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
                Establish branding guidelines and create marketing templates for
                brokerage use.
              </li>
              <li className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[25.2px] text-left m-0 text-clientDarkCharcoal ">
                Agent growth and marketing consultations.
              </li>
            </div>
          </div>

          <div
            className="w-[36%] h-auto
            tablet:w-[50%]
            tablet:ml-[10px]
           mobile:w-full mobile:mt-[10px]"
          >
            <img
              src={DrawingRoom2}
              alt="DrawingRoom"
              className="w-full h-full m-[0] rounded-[4px] "
            ></img>
          </div>
        </div>
        <div className="mt-[20px] text-center">
          <PopupButton className="p-[10px] rounded-[8px] bg-primary font-glacial text-[20px] font-bold leading-[20px] text-clientWhite hover:bg-opacity-80" url={CONTACT_US_CALENDLY_URL} rootElement={document.getElementById('root')}  text="Reach out to discuss" />
          </div>
      </div>

      <div className="max-w-[1280px] mx-auto w-full  py-[50px]" >
        <div className="mx-[25px]">
        <h1
          className="font-glacial text-[clamp(20px,_3vw,_25px)] font-bold leading-[25px] m-0 text-clientBlack 
         mobile:leading-[12.5px]" > Additional Services </h1>
        <div className="w-full mt-[25px]" >
          <ServiceCard ServiceCardData={ServiceCardData} toggleReadMore={toggleReadMore} setExpandedCard={setExpandedCard} expandedCard={expandedCard} />
        </div>
        </div>
        
      </div>
      <div className="mb-[20px] text-center">
          <PopupButton className="p-[10px] rounded-[8px] bg-primary font-glacial text-[20px] font-bold leading-[20px] text-clientWhite hover:bg-opacity-80" url={CONTACT_US_CALENDLY_URL} rootElement={document.getElementById('root')}  text="Reach out to discuss" />
          </div>
      <div
        className="max-w-[1280px] mx-auto flex justify-between items-center bg-clientNero pt-[40px] pb-[40px] pr-[128px]
        tablet:pr-[25px]
        mobile:pr-[10px]
        mobile:block mobile:p-[10px]"
      >
        <div
          className="w-[50%] h-auto
         mobile:w-full"
        >
          <img
            src={SpImage}
            alt="VillaImg"
            className="w-full h-auto m-[0]"
          ></img>
        </div>

        <div
          className="w-[46%] flex flex-col justify-center 
         mobile:w-full
         mobile:mt-[10px]"
        >
          <div>
            <h1 className="font-lejour  text-[clamp(25px,_3vw,_30px)] font-normal leading-[30px] m-0 text-clientWhite">
              Specialized consultation
            </h1>
          </div>

          <div className="mt-[20px]">
            <p className="text-justify font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[27px] m-0 text-clientWhite break-words">
              If you're exploring options for specific projects or need hourly
              consultation, please schedule a call with us! We’re ready to
              discuss how we can assist you on a per-project or per-hour basis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSection;
